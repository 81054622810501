import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { createAddItemLocation } from '../actions';

import { BASE_ZINDEX } from '../popup-factory';
import { getImageSrc } from '../utils';

class SelectDecorationLocationPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: props.item_locations.map(l => l.item_location_id)
    };

    this.handleSelectLocation = this.handleSelectLocation.bind(this);
  }

  handleSelectLocation(item_location_id) {
    if (this.state.selected.includes(item_location_id)) {
      this.setState({ selected: this.state.selected.filter(i => i !== item_location_id)});
    } else {
      this.setState({ selected: this.state.selected.concat([item_location_id])});
    }
  }

  render() {
    const { item_locations, item_id, onClosePopup, onAddItemLocation, index } = this.props;
    return (
      <div id="select-decoration-location-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Which locations would you like to copy?</h3>
            <a className="alert button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => {e.preventDefault(); onClosePopup();}}>Cancel</a>
            <a className="button" style={{position: 'fixed', left: '1rem', bottom: '1rem' }} onClick={e => {e.preventDefault(); onAddItemLocation(item_id, this.state.selected, 'no'); onClosePopup(2);}}>Copy Artwork Only</a>
            <a className="button" style={{position: 'fixed', left: '12rem', bottom: '1rem' }} onClick={e => {e.preventDefault(); onAddItemLocation(item_id, this.state.selected, 'yes'); onClosePopup(2);}}>Copy Artwork & Pricing</a>
          </div>
        </div>
        <div className="row column popup-content">
          {item_locations.map(l =>
            <div className="medium-4 small-6 columns" key={l.item_location_id} style={{float: 'left'}}>
              <div className="small-3 columns">
                <input type="checkbox" onChange={e => this.handleSelectLocation(l.item_location_id)} checked={this.state.selected.includes(l.item_location_id)}/>
              </div>
              <div className="small-9 columns artwork-container">
                <h5>{l.item_location_title}</h5>
                {l.artworks.map(a =>
                  <img key={a.artwork_id} src={getImageSrc(a.image)} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item_locations: ownProps.item_locations,
    item_id: ownProps.item_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    onAddItemLocation: (item_id, item_location_ids, copy_pricing) => {
      dispatch(createAddItemLocation(item_id, item_location_ids, 'COPY', copy_pricing));
    },
  };
};

const ConnectedSelectDecorationLocationPopup = connect(mapStateToProps, mapDispatchToProps)(SelectDecorationLocationPopup);
export default ConnectedSelectDecorationLocationPopup;

